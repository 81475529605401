.contactusButton{
    font-size:17px;
    border: 2px;
    padding: 10px 15px;
    border-radius: 20px;
    color:black;
    cursor:pointer;
    background:white;
    animation: gradient 8s ease infinite;

    @media only screen and (max-width:480px){
        font-size: 14px;
    }
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@media only screen and (max-width:480px){
    PopupButton{
        font-size: 10px;
    }
}